import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logoMaltesers: file(relativePath: { eq: "logo-maltesers.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <header className="bg-logo text-center mb-5">
        <figure className="pt-2 m-auto logo-maltesers">
          <Img fluid={data.logoMaltesers.childImageSharp.fluid} alt="" />
        </figure>
      </header>
    </>
  )
}

export default Header
