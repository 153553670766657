import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ChocolateBarImg = () => {
  const data = useStaticQuery(graphql`
    query {
      ChocolateBarImgLeft: file(relativePath: { eq: "bg-chocolate-left.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      ChocolateBarImgRight: file(
        relativePath: { eq: "bg-chocolate-right.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return (
    <>
      <figure className="position-absolute bg-chocolate-left d-none d-md-block">
        <Img fluid={data.ChocolateBarImgLeft.childImageSharp.fluid} alt="" />
      </figure>

      <figure className="position-absolute bg-chocolate-right d-none d-md-block">
        <Img fluid={data.ChocolateBarImgRight.childImageSharp.fluid} alt="" />
      </figure>
    </>
  )
}

export default ChocolateBarImg
