import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
//components

import Layout from "../../components/MaltesersBakeAgainstTheMachineComponents/components/layout"
import SEO from "../../components/MaltesersBakeAgainstTheMachineComponents/components/seo"
import Header from "../../components/MaltesersBakeAgainstTheMachineComponents/components/header"
import LogoBakeAgainst from "../../components/MaltesersBakeAgainstTheMachineComponents/components/logo-bake-against"
import ChocolateBarImg from "../../components/MaltesersBakeAgainstTheMachineComponents/components/chocolate-bar-img"

const TermsConditions = () => {
  const [showQuizForm, setShowQuizForm] = useState(false)
  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="../MaltesersBakeAgainstTheMachine/css/layout.css"
        />
        <link
          rel="stylesheet"
          href="../MaltesersBakeAgainstTheMachine/css/layout.media.css"
        />
      </Helmet>
      <Layout pageInfo={{ pageName: "Terms&Conditions" }}>
        <SEO
          title="Terms and Conditions"
          keywords={[`gatsby`, `react`, `bootstrap`]}
        />
        <div
          className={`${
            showQuizForm ? "bg-top-section-home" : "bg-top-section"
          } age-gate-padding position-relative`}
        >
          <Header />
          <div className="text-center">
            <LogoBakeAgainst />
            <ChocolateBarImg />
          </div>
        </div>
      </Layout>
    </>
  )
}
export default TermsConditions
