import React, { useEffect } from "react"
import Helmet from "react-helmet"

// import "./layout.css"
// import "./layout.media.css"

const Layout = ({ children }) => {

  return (
    <>
      <Helmet>
        {/* <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/consent/75106614-e1c2-4a5b-acb3-5a8e888f2d73/OtAutoBlock.js"
        ></script> */}

        {/* Stage */}
        {/* <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="eb9d3dcb-f32f-4c05-ad1c-f17ef4afda01"
        ></script> */}

        {/* Production */}
        {/* <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="a86204b2-d719-4c58-ad83-641c82d0d603-test"
        ></script> */}
      </Helmet>

      <main>{children}</main>
    </>
  )
}

export default Layout
